<template>
  <div class="card border-0">
    <div class="card-header f-w-600">用户账号</div>
    <div class="card-body height-auto m-0 p-0">
      <form v-on:submit="checkForm" method="POST">
        <div class="panel panel-inverse" data-sortable-id="form-stuff-1">
          <!-- panel-body begin -->
          <div class="panel-body">
            <div class="row form-group m-b-10">
              <label class="col-lg-2 col-md-3 col-form-label text-right"
                >角色：</label
              >
              <div v-if="id" class="col-lg-8 col-md-9 m-b-10">
                <v-select
                  disabled="disabled"
                  v-model="CompanyUser.roleId"
                  @input="getrolesIDSelect"
                  :reduce="
                    (getrolesEquipmentDict) => getrolesEquipmentDict.value
                  "
                  :options="getrolesEquipmentDict"
                ></v-select>
              </div>
              <div v-else class="col-lg-8 col-md-9 m-b-10">
                <v-select
                  v-model="CompanyUser.roleId"
                  @input="getrolesIDSelect"
                  :reduce="
                    (getrolesEquipmentDict) => getrolesEquipmentDict.value
                  "
                  :options="getrolesEquipmentDict"
                ></v-select>
              </div>
            </div>
            <div class="row form-group m-b-10">
              <label class="col-lg-2 col-md-3 col-form-label text-right"
                >账号：</label
              >
              <div v-if="id" class="col-lg-8 col-md-9">
                <div class="input-group">
                  <input
                    class="form-control"
                    data-parsley-range="[5,10]"
                    v-model="CompanyUser.userName"
                  />
                </div>
              </div>
              <div v-else class="col-lg-8 col-md-9">
                <div class="input-group">
                  <input
                    class="form-control"
                    data-parsley-range="[5,10]"
                    v-model="CompanyUser.userName"
                    required
                  />
                </div>
              </div>
            </div>
            <div class="row form-group m-b-10">
              <label class="col-lg-2 col-md-3 col-form-label text-right"
                >姓名：</label
              >
              <div class="col-lg-8 col-md-9">
                <div class="input-group">
                  <input
                    class="form-control"
                    data-parsley-range="[5,10]"
                    v-model="CompanyUser.name"
                  />
                </div>
              </div>
            </div>
            <div class="row form-group m-b-10">
              <label class="col-lg-2 col-md-3 col-form-label text-right"
                >密码：</label
              >
              <div class="col-lg-8 col-md-9">
                <div class="input-group">
                  <input
                    class="form-control"
                    data-parsley-range="[5,10]"
                    v-model="CompanyUser.password"
                  />
                </div>
              </div>
            </div>
            <div class="row form-group m-b-10">
              <label class="col-lg-2 col-md-3 col-form-label text-right"
                >电话号码：</label
              >
              <div class="col-lg-8 col-md-9">
                <div class="input-group">
                  <input
                    class="form-control"
                    data-parsley-range="[5,10]"
                    v-model="CompanyUser.phoneNumber"
                  />
                </div>
              </div>
            </div>
            <!-- 所属区域当用户为网格长时显示 -->
            <div v-if="CompanyUser.roleId == 2" class="row form-group m-b-10">
              <label class="col-lg-2 col-md-3 col-form-label text-right"
                >所属区域：</label
              >
              <div class="col-lg-8 col-md-9">
                <dictSelect
                  :show="false"
                  :value="CompanyUser.regionId"
                  @select="regionIdIdSelect"
                  :pid="'3a019cfc-ef88-6b72-7bc4-f7c04aa3e94c'"
                ></dictSelect>
              </div>
            </div>
            <div v-else></div>

            <div v-if="CompanyUser.roleId == 1" class="row form-group">
              <label class="col-lg-2 col-md-4 col-form-label text-right"
                >所属企业：</label
              >
              <div class="col-lg-8 col-md-8 width=500">
                <vxe-pulldown ref="xDown" transfer>
                  <template v-if="id" #default>
                    <vxe-input readonly v-model="BusinessName"></vxe-input>
                  </template>
                  <template v-else #default>
                    <vxe-input
                      v-model="BusinessName"
                      suffix-icon="fa fa-search"
                      @focus="BusinessIdfocusEvent"
                      @keyup="BusinessIdkeyupEvent"
                    ></vxe-input>
                  </template>
                  <template #dropdown>
                    <div class="my-dropdown4">
                      <vxe-grid
                        resizable
                        :seq-config="{
                          startIndex:
                            (tablePage.currentPage - 1) * tablePage.pageSize,
                        }"
                        border
                        highlight-hover-row
                        auto-resize
                        height="300px;"
                        :loading="loading"
                        :pager-config="tablePage"
                        :data="tableData"
                        :columns="tableColumn"
                        @cell-click="cellClickEvent"
                      >
                        <template #pager>
                          <vxe-pager
                            :layouts="[
                              'Sizes',
                              'PrevJump',
                              'PrevPage',
                              'Number',
                              'NextPage',
                              'NextJump',
                              'FullJump',
                              'Total',
                            ]"
                            :current-page.sync="tablePage.currentPage"
                            :page-size.sync="tablePage.pageSize"
                            :total="tablePage.totalResult"
                            @page-change="pageChangeEvent"
                          >
                          </vxe-pager>
                        </template>
                      </vxe-grid>
                    </div>
                  </template>
                </vxe-pulldown>
              </div>
            </div>
            <div v-else></div>
            <div class="row form-group m-b-10">
              <label
                class="col-xxl-2 col-lg-4 col-md-4 col-form-label text-right"
                >状态：</label
              >
              <div class="col-xxl-8 col-lg-8 col-lg-8">
                <div class="radio radio-css radio-inline m-r-15">
                  <input
                    type="radio"
                    id="typeRadio"
                    name="type"
                    v-model="CompanyUser.isActive"
                    value="true"
                    checked
                  />
                  <label for="typeRadio" class="m-r-15"> 启用</label>
                  <input
                    type="radio"
                    id="typeRadio1"
                    name="type"
                    v-model="CompanyUser.isActive"
                    value="false"
                  />
                  <label for="typeRadio1"> 禁用</label>
                </div>
              </div>
            </div>
          </div>
        </div>
      </form>
      <div class="card-footer text-right f-w-600">
        <button
          class="btn btn-sm btn-success m-r-10"
          type="submit"
          @click="checkForm"
        >
          <i class="fa fa-save"></i> 保存
        </button>
        <button
          type="button"
          @click="$emit('close')"
          class="btn btn-sm btn-grey m-r-10"
        >
          ❌关闭
        </button>
      </div>
    </div>
  </div>
</template>
<script>
import { businessuserApi } from "@/api/system/businessuserApi";
import { businessApi } from "@/api/system/businessApi";
import dictSelect from "@/components/dictSelect/index.vue";
export default {
  props: ["CompanyUserid"],
  components: {
    dictSelect,
  },
  data() {
    return {
      loading: false,
      CompanyUser: {
        userName: "",
        name: "",
        password: "",
        isActive: "true",
        phoneNumber: "",
        roleId: "",
        regionId: "",
        businessId: "",
        id: "",
      },
      getrolesEquipmentDict: [],
      BusinessName: "",
      tableColumn: [
        { field: "gridName", title: "网格长", width: 80 },
        { field: "name", title: "企业名称", width: 300 },
        { field: "regionName", title: "所属区域", width: 100 },
      ],
      tableData: [],
      tableList: [],
      tablePage: {
        currentPage: 1,
        pageSize: 10,
        totalResult: "",
      },
      id: [],
    };
  },
  mounted() {
    let id = this.CompanyUserid;
    this.id = id;
    if (id) {
      this.GetCompanyUser();
    }
    this.GetCompanyData();
    this.Getgetroles();
  },
  methods: {
    ToastSuccess(res) {
      const Toast = this.$swal.mixin({
        toast: true,
        position: "top-end",
        showConfirmButton: false,
        timer: 2000,
        timerProgressBar: true,
        didOpen: (toast) => {
          toast.addEventListener("mouseenter", this.$swal.stopTimer);
          toast.addEventListener("mouseleave", this.$swal.resumeTimer);
        },
      });
      var title = [];
      if (res.message == "Success") {
        title = "保存成功";
      } else {
        title = res.message;
      }
      Toast.fire({
        icon: "success",
        title: title,
      });
    },
    ToastWarning(res) {
      const Toast = this.$swal.mixin({
        toast: true,
        position: "top-end",
        showConfirmButton: false,
        timer: 2000,
        timerProgressBar: true,
        didOpen: (toast) => {
          toast.addEventListener("mouseenter", this.$swal.stopTimer);
          toast.addEventListener("mouseleave", this.$swal.resumeTimer);
        },
      });
      var title = [];
      if (res.message == "False") {
        title = "保存出错";
      } else {
        title = res.message;
      }
      Toast.fire({
        icon: "warning",
        title: title,
      });
    },
    GetCompanyUser() {
      let that = this;
      businessuserApi
        .GetCompanyUser({
          id: that.id,
        })
        .then((res) => that.GetCompanyUserSuccess(that, res))
        .finally(() => {});
    },

    checkForm: function(e) {
      e.preventDefault();
      const params = {
        id: this.id,
        UserName: this.CompanyUser.userName,
        Name: this.CompanyUser.name,
        Password: this.CompanyUser.password,
        IsActive: this.CompanyUser.isActive,
        RegionId: this.CompanyUser.regionId,
        RoleId: this.CompanyUser.roleId,
        PhoneNumber: this.CompanyUser.phoneNumber,
        BusinessId: this.CompanyUser.businessId,
      };
      if (this.CompanyUser.roleId == null) {
        this.$swal({
          title: "",
          icon: "warning",
          text: "请选择用户角色", //提示语
          type: "question", //类型
          showCancelButton: false, //  显示取消按钮
          buttonsStyling: false,
          // confirmButtonText: "确定",
          // cancelButtonText: "取消",
          confirmButtonClass: "btn m-r-5 btn-" + "error" + "",
          cancelButtonClass: "btn btn-default",
        });
        return;
      } else {
        //e.preventDefault();
        let that = this;
        if (that.id) {
          businessuserApi
            .UpdateCompanyUser(params)
            .then((res) => that.UpdateCompanyUserSuccess(that, res))
            .finally(() => {});
        } else {
          businessuserApi
            .CreateCompanyUser(params)
            .then((res) => that.CreateCompanyUserSuccess(that, res))
            .finally(() => {});
        }
        this.$emit("close");
      }
    },
    GetCompanyUserSuccess(that, res) {
      if (res.success) {
        this.GetData(res.data.extraProperties.BusinessId); //GetData:获取根据企业ID一个企业信息
        that.CompanyUser = res.data;
        that.CompanyUser.regionId = res.data.extraProperties.RegionId;
      } else {
        that.ToastWarning(res);
      }
    },
    CreateCompanyUserSuccess(that, res) {
      if (res.success) {
        this.$router.push({ path: "/CompanyUserList" });
        that.ToastSuccess(res);
        that.CompanyUser = res.data;
      } else {
        that.ToastWarning(res);
      }
    },
    UpdateCompanyUserSuccess(that, res) {
      if (res.success) {
        that.ToastSuccess(res);
        // this.$router.push({ path: "/CompanyUserList" });
        that.CompanyUser = res.data;
        that.CompanyUser.regionId = res.data.extraProperties.RegionId;
      } else {
        that.ToastWarning(res);
      }
    },
    // 所属区域选择框返回值然后赋值
    regionIdIdSelect(id) {
      this.CompanyUser.regionId = id;
      console.log("regionIdIdSelect", id);
    },
    finish(e) {
      console.log(e);
      this.CompanyUser.cover = e[0].res.data.fileInfo.id;
      console.log(e[0].res.data.fileInfo.id);
    },

    //企业查询框
    GetCompanyData() {
      let that = this;
      businessApi
        .GetCompanyData({
          // 分页入参
          SkipCount: (that.tablePage.currentPage - 1) * that.tablePage.pageSize, //起始页数
          MaxResultCount: that.tablePage.pageSize, //每页几条
          Sorting: "", //排序字段
          Filter: this.BusinessName, //名称模糊搜索
        })
        .then((res) => that.GetSuccess(that, res))
        .finally(() => {});
    },

    // 获取成功
    GetSuccess(that, res) {
      if (res.success) {
        that.tableData = res.data.items;
        that.tableList = res.data.items;
        that.tablePage.totalResult = res.data.totalCount;
      } else {
        console.log(res);
      }
    },

    BusinessIdfocusEvent() {
      this.$refs.xDown.showPanel();
    },
    //键盘抬起事件
    BusinessIdkeyupEvent() {
      this.GetCompanyData();
    },

    BusinessIdsuffixClick() {
      this.$refs.xDown.togglePanel();
    },
    //下拉表格选择事件
    cellClickEvent({ row }) {
      this.BusinessName = row.name;
      (this.CompanyUser.businessId = row.id), this.GetData(row.id);
    },
    // 获取企业名称
    GetData(businessId) {
      let that = this;
      businessApi
        .GetData({
          id: businessId,
        })
        .then((res) => that.GetDataSuccess(that, res))
        .finally(() => {});
    },
    GetDataSuccess(that, res) {
      if (res.data) {
        that.BusinessName = res.data.name; //把企业名称赋值给BusinessName
      }
    },
    pageChangeEvent({ currentPage, pageSize }) {
      this.tablePage.currentPage = currentPage;
      this.tablePage.pageSize = pageSize;
      this.GetCompanyData();
    },
    //角色选择框返回值
    getrolesIDSelect(id) {
      console.log("getrolesIDSelect", id);
      this.CompanyUser.roleId = id;
    },

    //角色选择框
    Getgetroles() {
      let that = this;
      // let options = [];
      // that.getrolesEquipmentDict = [],
      // businessuserApi.Getgetroles({}).then((res) => {
      //   res.data.items.forEach((element) => {
      //     options.push({
      //       value: element.id,
      //       label: element.name,
      //     });
      //   });
      //   that.getrolesEquipmentDict = options;
      // });
      //选择框的optoin写死
      that.getrolesEquipmentDict = [
        { value: 0, label: "系统管理员" },
        { value: 1, label: "企业用户" },
        { value: 2, label: "网格长" },
      ];
    },
  },
};
</script>
<style>
.my-dropdown4 {
  width: 500px;
  height: 350px;
  background-color: #fff;
  box-shadow: 0 0 6px 2px rgba(0, 0, 0, 0.1);
}
.vxe-input {
  display: inline-block;
  position: relative;
  width: 485px;
}
</style>
