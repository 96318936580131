import {
    propertyService
} from '@/api/utils/request'
const apiUrl = {
    GetCompanyUserList: '/api/users/gets',
    GetCompanyUser: '/api/users/get',
    CreateCompanyUser: '/api/users/create',
    UpdateCompanyUser: '/api/users/update',
    DeleteCompanyUser: '/api/users/delete',
    Getgetroles: "/api/users/getroles",
}
const businessuserApi = {
    //获取企业用户列表
    GetCompanyUserList(parameter) {
        return propertyService({
            url: apiUrl.GetCompanyUserList,
            method: 'get',
            params: parameter,
            needToken: true
        })
    },
    //新建账号
    CreateCompanyUser(parameter) {
        return propertyService({
            url: apiUrl.CreateCompanyUser,
            method: 'post',
            data: parameter,
            needToken: true
        })
    },
    //修改账号
    UpdateCompanyUser(parameter) {
        return propertyService({
            url: apiUrl.UpdateCompanyUser,
            method: 'post',
            data: parameter,
            needToken: true
        })
    },
    //获取企业用户
    GetCompanyUser(parameter) {
        return propertyService({
            url: apiUrl.GetCompanyUser,
            method: 'get',
            params: parameter,
            needToken: true
        })
    },
    //删除一个账号
    DeleteCompanyUser(parameter) {
        return propertyService({
            url: apiUrl.DeleteCompanyUser,
            method: 'delete',
            params: parameter,
            needToken: true
        })
    },
//获取角色列表
    Getgetroles(parameter) {
        return propertyService({
            url: apiUrl.Getgetroles,
            method: 'get',
            params: parameter,
            needToken: true
        })
    },
}
export {
    businessuserApi
}